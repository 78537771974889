import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="Referral Policy" />
    <div className="common-page privacy-page">
      <HeadingSection h1="Referral Policy" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <p>We reward you when you help us spread the word about Sprive.</p>

      <h4>Who can invite an Eligible Person under this referral program?</h4>
      <p>
        Any Sprive user who has successfully setup a direct debit with their Sprive
        account can invite Eligible People to use Sprive by giving them a unique
        signup link.
      </p>

      <h4>Who qualifies as an Eligible Person?</h4>
      <p>
        An Eligible Person can be anyone over the age of 18 who is not an existing
        Sprive user at the time they are invited. A Sprive user is defined as someone
        who has downloaded the app and agreed to our Terms & Conditions.
      </p>
      <h4>Will I get a referral bonus?</h4>
      <p>
        The referral bonus will vary depending on the campaign you are enrolled in.
        Some campaigns don’t offer a referral bonus.
      </p>
      <h4>How long does the Referral Campaign last for?</h4>
      <p>
        Each campaign has different durations. You can check the duration of the
        campaign in the email we sent to you when it started and in the referrals
        section within the app
      </p>
      <h4>How many friends can I refer to Sprive?</h4>
      <p>
        Depending on the campaign you are enrolled in, the number of friends you
        can invite will change. You can check the limit of the campaign in the email we
        sent to you when it started.
      </p>
      <h4>How does the Sprive Referral Programme work?</h4>
      <p>
        Once your referral link has been used successfully we'll send you an in-app
        notification. You will receive a notification after every successful invite. You
        can track the progress within the ‘invite’ menu.</p>
      <h4>What makes a referral successful?</h4>
      <p>
        Depending on the campaign you are enrolled in, the referee might have to
        complete different actions in order to be considered a successful invite. You
        can check the campaign goal in the email we sent to you when it started.
        Subject to our reviews and checks, your referral bonus will be paid into your
        Sprive account within 14 working days of your invite being successfully used.
      </p>

      <h4>What counts as a successful invite?</h4>
      <p>
        We deem a referral invite is successful once all the following criteria are met:
        <ol>
          <li>
            <p>the invite was sent to an Eligible Person;</p>
          </li>
          <li>
            <p>they used your unique link to sign up to Sprive;</p>
          </li>
          <li>
            <p>they accepted our Terms & Conditions;</p>
          </li>
          <li>
            <p>they completed the sign up process and setup an active direct debit with your Sprive account;</p>
          </li>
          <li>
            <p>the referral was made in good faith;</p>
          </li>
          <li>
            <p>the referee has completed any additional steps that have been communicated to you at the beginning of the campaign</p>
          </li>
        </ol>
      </p>

      <h4>You may not:</h4>
      <p>
        <ol>
          <p>(a) invite others that have created duplicate accounts;</p>
          <p>(b) use alternative contact information to refer yourself or others;</p>
          <p>(c) create more than one account in order to invite yourself;</p>
          <p>(d) invite an existing Sprive user;</p>
          <p>(e) invite fictitious persons;</p>
          <p>(f) do anything that might damage Sprive’s brand, goodwill or reputation;</p>
          <p>(g) invite someone by suggesting they'll receive a referral bonus, or by
            promoting anything other than Sprive’s main purpose, which is helping people
            pay off their mortgage faster;</p>
          <p>(h) use the Referral Program in: (i) any way that breaches any applicable
            local, national or international law or regulation; or (ii) any way that is unlawful
            or fraudulent, or has any unlawful or fraudulent purpose or effect; (iii) in any
            way that contravenes any other agreements we have with you, including our
            Terms and Website Use Policy;</p>
          <p>(i) transmit any unsolicited or unauthorised advertising or promotional material
            or any other form of similar solicitation (spam); or</p>
          <p>(j) transmit any unsolicited or unauthorised advertising either via paid search
            or any other similar activity, including channels that can damage the Sprive
            brand, goodwill or reputation.</p>

        </ol>
      </p>

      <h4>What other legal information should I know?</h4>
      <p>
        Sprive reserves the right to limit the invite rewards if we suspect you did not
        use the invite program in good faith, or have breached any of the above terms.
        In cases of suspected fraud, further action may result in the suspension of
        your Sprive account, and your Referral Bonus being withheld from being paid
        to you. If you believe you’re owed a Referral Bonus that hasn’t been paid,
        please email us at <a href="mailto:hello@sprive.com" type="email">
            hello@sprive.com
          </a>. In most cases these issues can be
        quickly investigated and resolved.
      </p>
      <p>
        We may, at our sole discretion, suspend, terminate or change the terms and
        requirements of the Referral Program at any time. These terms are published
        in English, and any translation is provided as a courtesy only. Participants in
        the Referral Program cannot derive any rights from the translated version.
        The English language version of these terms shall apply and prevail and be
        conclusive and binding. The English version shall be used in all legal
        proceedings to the extent permitted by law.
      </p>
      <p>
        These Referral Program Terms shall be exclusively governed by and
        construed in accordance with the laws of England and Wales. Any disputes
        arising out of or in connection with these terms shall exclusively be submitted
        to and dealt with by the competent court in England.
      </p>
      <p>
        In order to benefit from this Referral Scheme, you must be an existing
        customer of Sprive and have agreed to our General Terms and Conditions.
        Any additional information provided by you in the course of a referral will be
        treated in line with the Terms & Conditions and Data Privacy Policy.
      </p>

    </div>
  </section>
);

export default PrivacyPage;
